import { For, Show, createEffect, splitProps } from "solid-js";
import { ShowMaterialLessonBlockViewProps } from "../../../models";
import { Group } from "./group";
import { elementStyleToHTMLStyle } from "../../../methods";
export function Block(props: ShowMaterialLessonBlockViewProps) {
  const [local, others] = splitProps(props, ["block", "style"]);
  let ref: HTMLDivElement = undefined;
  createEffect(() => {
    if (ref) {
      ref.setAttribute("style", elementStyleToHTMLStyle(local.block.style));
    }
    // console.log("updated show block:: ", local.block);
  });
  return (
    <div {...others} ref={ref}>
      <Show when={local.block.groups && local.block.groups.length > 0} fallback={local.block.name ?? "Unedited Block"}>
        <For each={local.block.groups}>
          {(group, index) => {
            return <Group group={group} />;
          }}
        </For>
      </Show>
    </div>
  );
}
